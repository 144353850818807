import {
  Index,
  EnumLoginState,
  UserIdentity,
  RegisterDeviceResponse,
} from "./interfaces";
import { getUserData } from "./services/user";
import { Jwt } from "./jwt";
import {
  CookiesConstants,
  LOGIN_SUCCESS_CONSTANT,
  ScreenConstants,
  OPEN_SSO_MODAL_EVENT, SsoScreenTypes,
  ON_CONTINUE_CB,
} from "./constants";
import {
  destroyCookie,
  getCookie,
  jsonSafeGet,
  parseCookies,
} from "./helpers";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import envConfig from "./env.config";
import { logout } from "./dataLayer/apisWithoutCache/slr/logout";
import { generateRequiredCookiesAndGetToken } from "./helpers/tgtHelper";
import {IMember} from "./models/member";

export default class Auth implements Index {
  private static instance: Auth;
  private domainName!: string;
  private rootElement!: string;
  public language!: "ar" | "en";
  public country!: string;
  public platform!: string;
  public cityId!: number;
  public auth!: RegisterDeviceResponse;
  public identity?: UserIdentity;
  private initCalled = false;
  private state = null;
  public screenType: SsoScreenTypes;
  public memberData: IMember;

  public static getInstance(): Auth {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  private getState = async (isInit = false): Promise<EnumLoginState> => {
    const cookies = parseCookies();
    const userInfo = jsonSafeGet<UserIdentity>(
      cookies[envConfig.COOKIES_PREFIX + "userInfo"]
    );
    const sub = this?.identity?.id || userInfo?.id;
    if (!sub) return EnumLoginState.LoggedOut;
    try {
      if (isInit) {
        this.identity = (await getUserData()) as UserIdentity;
      } else if (!this?.identity?.id || !userInfo?.id) {
        this.identity = (await getUserData()) as UserIdentity;
      }
    } catch (e) {
      console.error("Error While Get User Data->", e.message);
    }

    return this.identity?.id && this.identity.id > 0
      ? EnumLoginState.LoggedIn
      : EnumLoginState.LoggedOut;
  };

  public async init(
    domainName: "opensooq.com" | string,
    rootElement: string,
    language: "ar" | "en",
    country: string,
    platform: string,
    deviceUUID: string,
    cityId: number
  ): Promise<{
    state: EnumLoginState;
  }> {
    if (this.initCalled) {
      console.error(
        "Trying to call Auth.init multiple times, only first call will work!"
      );
      return this.state;
    }
    this.initCalled = true;
    this.domainName = domainName;
    this.rootElement = rootElement;
    this.language = language;
    this.country = country;
    this.platform = platform;
    this.cityId = cityId;
    this.auth = await generateRequiredCookiesAndGetToken(this.platform);
    if (this.rootElement) {
        const _ssoRoot = document?.getElementById(this.rootElement);
        const ssoRoot = createRoot(_ssoRoot);
        ssoRoot.render(<App/>);
    }
    try {
      const state = (this.state = await this.getState(true));
      return { state };
    } catch (err) {
      console.error(`Error while getting member data`, err);
      throw err;
    }
  }

  private controller: AbortController | undefined;

  public async getToken(authenticated: boolean, verified: boolean = false, onContinueCb = () => {}): Promise<string> {
    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const signal = this.controller.signal;

    let screenType = (authenticated && !this.identity) ? ScreenConstants.LOGIN_SCREEN :
        (verified && this.identity?.isNewUser) ? ScreenConstants.COMPLETE_REGISTER : null;

    if (screenType) {
      this.screenType = screenType;
      document.dispatchEvent(
          new CustomEvent(OPEN_SSO_MODAL_EVENT, {
            detail: {
              country: this.country,
              deviceUUID: this.auth.deviceUUID,
              platform: this.platform,
              language: this.language,
              cityId: this.cityId,
            },
          })
      );

      await new Promise<string>((resolve, reject) => {
        const loginSuccessHandler = () => {
          window[ON_CONTINUE_CB] = onContinueCb;
          resolve("jwt");
          document.removeEventListener(LOGIN_SUCCESS_CONSTANT, loginSuccessHandler);
        };

        const abortHandler = () => {
          document.removeEventListener(LOGIN_SUCCESS_CONSTANT, loginSuccessHandler);
        };

        document.addEventListener(LOGIN_SUCCESS_CONSTANT, loginSuccessHandler);
        signal.addEventListener("abort", abortHandler);
      });
    }

    const state = await this.getState();
    if (authenticated && state === EnumLoginState.LoggedOut) {
      return await this.getToken(authenticated, verified, onContinueCb);
    }

    const jwt = new Jwt();
    const payload = {
      at0: this.auth.t,
      aud: this.platform,
      sub: this.identity ? JSON.parse(getCookie(envConfig.COOKIES_PREFIX + 'userInfo'))?.['id'] : this.auth.deviceUUID,
    };
    return jwt.generate_jwt(this.auth.k, payload);
  }


  public async authenticate(
      redirectUrl: string,
      verified: boolean = false
  ): Promise<void> {
    const country = this.country;
    const deviceUUID = this.auth.deviceUUID;
    const platform = this.platform;
    const language = this.language;
    const cityId = this.cityId;
    let dialogType = "";
    if (this.identity?.id) {
      await this.getState();
    }
    if (
        !this.identity?.id ||
        (this.identity.id && verified && this.identity.isNewUser)
    ) {
      if (!this.identity?.id) {
        redirectUrl = encodeURIComponent(redirectUrl);
      } else if (this.identity.id && verified && this.identity.isNewUser) {
        dialogType = ScreenConstants.COMPLETE_REGISTER;
      }
      const url = `${envConfig.OPENSOOQ_SSO_BASE_DOMAIN}/login?redirectUrl=${redirectUrl}&cityId=${cityId}&country=${country}&deviceUUID=${deviceUUID}&platform=${platform}&language=${language}&dialogType=${dialogType}`;
      window.location.href = url;
    } else {
      if (window.location.href !== redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  }

  public async getIdentity(): Promise<UserIdentity | undefined> {
    await this.getState();
    return this.identity;
  }

  public async setAuth(): Promise<void> {
    this.auth = await generateRequiredCookiesAndGetToken(this.platform);
  }

  public async changePassword(onContinueCb = () => {}): Promise<void> {
    const state = await this.getState();
    if (state === EnumLoginState.LoggedIn) {
      this.screenType = ScreenConstants.CHANGE_PASSWORD;
      this.memberData =  await this.getIdentity()
      window[ON_CONTINUE_CB] = onContinueCb;
      document.dispatchEvent(
        new CustomEvent(OPEN_SSO_MODAL_EVENT, {
          detail: {
            country: this.country,
            deviceUUID: this.auth.deviceUUID,
            platform: this.platform,
            language: this.language,
            cityId: this.cityId,
          },
        })
      );
    } else {
      await this.getToken(true, true);
    }
  }

  public async changeMobilePhone(onContinueCb = () => {}) {
    const state = await this.getState();
    if (state === EnumLoginState.LoggedIn) {
      this.screenType = ScreenConstants.CHANGE_PHONE_NUMBER;
      this.memberData =  await this.getIdentity()
      window[ON_CONTINUE_CB] = onContinueCb;
      document.dispatchEvent(
          new CustomEvent(OPEN_SSO_MODAL_EVENT, {
            detail: {
              country: this.country,
              deviceUUID: this.auth.deviceUUID,
              platform: this.platform,
              language: this.language,
              cityId: this.cityId,
            },
          })
      );
    } else {
      await this.getToken(true, false);
    }
  }

  public getBaseDomain () {
    return this.domainName
  }
  public async destroy(): Promise<void> {
    const cookies = parseCookies();
    const fcmToken = cookies[CookiesConstants.FIREBASE_FCM_TOKEN] ?? null;
    if (this.identity) {
      try {
        await logout(fcmToken);
      } catch (err) {
        console.error(`Error while getting member data`, err);
      }
    }

    const logoutCookies = [
      CookiesConstants.AUTH,
      CookiesConstants.USER_INFO,
      CookiesConstants.AUTH_V2,
    ];
    logoutCookies.forEach((logoutCookie) => {
      destroyCookie(logoutCookie);
    });

    delete this.identity;
    delete this.auth;
    this.initCalled = false;
    delete this.state;
  }
}