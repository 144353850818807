import {ITranslation} from "./trInterface";

const arabic: ITranslation = {
    LoginOrRegister: "تسجيل الدخول أو التسجيل",
    help: "المساعدة؟",
    bestWay:"أفضل طريقة",
    buyOrSell:"لبيع أو شراء أي شيء",
    homeWelcome1: "انضم إلى ملايين الأشخاص على السوق المفتوح",
    homeWelcome2: "تسجيل الدخول يعزز الثقة والأمان",
    homeWelcome3: "أجب على الرسائل و العروض",
    homeWelcome4: "إدارة الإعلانات المفضلة والمحفوظة",
    homeWelcome5: "أضف أي شيء للبيع او للإيجار او وظيفة",
    enter: "أدخل",
    mobileNumber: 'رقم الموبايل',
    next: "التالي",
    byUsingOpenSooq: "باستخدامك السوق المفتوح أنت توافق على",
    termsOfUse: "إتفاقية الاستخدام",
    and: "و",
    contentPolicy: "سياسة المحتوى",
    password: "كلمة المرور",
    pleaseFill: "الرجاء تعبئة",
    tryAgain: "حاول مرة أخرى",
    forgetPassword: "نسيت كلمة المرور؟",
    rememberMe: "تذكرني",
    loggedInSuccessfully: "تم تسجيل الدخول بنجاح",
    passChangedSuccessfully: "تم تغيير كلمة المرور بنجاح",
    logoutFromAllDevices: "لقد تم تسجيل الخروج من جميع الأجهزة الأخرى التي تستخدم هذا الحساب",
    AddListingOrContinueBrowsing: "قم بإضافة إعلان أو تصفح الإعلانات الآن",
    continue: "متابعة",
    addListing: "أضف إعلان",
    verifyMobileNumber: "قم بتوثيق رقم الموبايل",
    verifyMobileNumberP: "لبناء الثقة وحماية المستخدمين بموجب القانون المحلي قم بتوثيق رقم الموبايل ",
    VerifyViaWhatsApp: "وثّق عبر الواتساب",
    VerifyViaSMS: "وثّق عبر SMS",
    VerifyViaViber: "وثّق عبر فايبر",
    VerificationCode: "رمز التحقق",
    VerificationCodeP: "الرجاء إدخال الرمز الذي وصلك على الواتساب أو SMS.",
    registrationCompleted: "تم التسجيل",
    nowVerified: "تم توثيق رقم الموبايل [MOBILE_NUM]",
    resend: "أعد إرسال الرمز",
    changePassword: "تعديل كلمة المرور",
    newPassword: "كلمة المرور الجديدة",
    newPassword2: "أدخل كلمة المرور الجديدة",
    confirmNewPassword: "تأكيد كلمة المرور الجديدة",
    currentPassword: "كلمة المرور الحالية",
    save: "حفظ",
    forgotPassword: "نسيت كلمة المرور",
    forgotPasswordP: "سوف نقوم بإرسال رمز أو رابط التوثيق إلى رقم الموبايل أدناه لتتمكن من تغيير كلمة المرور",
    completeRegistration: "أكمل التسجيل",
    completeRegistrationP: "الرجاء تعبئة النموذج لإكمال التسجيل. سيسمح لك ذلك بإضافة الإعلانات والدردشة مع الآخرين.",
    uploadPhoto: "قم بتحميل صورة لحسابك",
    fullName: "الاسم الكامل",
    email: "البريد الإلكتروني",
    changeMobileNumber: "تغيير رقم الموبايل",
    changeMobileNumberP: "أضف رقم موبايل جديد للتوثيق",
    yes: "نعم",
    no: "لا",
    mobileNumberUsed: "رقم الموبايل مسجّل في حساب آخر",
    mobileNumberUsedP: "هل تريد تسجيل الخروج من هذا الحساب والدخول إلى حساب آخر؟",
    searchForCountry: "ابحث عن دولة",
    doYouNeedHelp: "هل تحتاج إلى مساعدة؟",
    whatsapp: "واتساب",
    contactUsNow: "اتصل بنا الآن",
    complete: "أكمل",
    later: "لاحقا",
    openSooqSso: {
        title: "تسجيل الدخول - السوق المفتوح"
    }


};

export default arabic;
