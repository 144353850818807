import React, {
  createContext,
  FC,
  ReactNode,
  useContext, useEffect,
  useReducer,
} from "react";
import {
  ICountryAsset,
  ICountryAssetLite,
} from "../models/Assets/ICountryAsset";
import {filterCountryByCountryCode, getCountryByCountryAbbreviation} from "../dataLayer/filters/filterCountryByCountryCode";
import Auth from "../auth";
import { LocalStorageConstants, SsoScreenTypes } from "../constants";
import { ISsoConfiguration } from "../models/ssoConfigurations";
import { IVerification } from "../ComponentScreeens/VerifyScreen";
import localStorageUtility from "../hooks/localStorageUtility";
import { ILinkOtpData } from "../models/otp";
import {useGtmDefaultData} from "../GTMs/GtmData";
import {IMember} from "../models/member";

interface IAppContext {
  children?: ReactNode;
  countries?: ICountryAsset[];
  flowState?: IFlowState;
  dispatch?: React.Dispatch<IFlowState>;
  ssoConfigurations?: ISsoConfiguration;
  otpData?: ILinkOtpData;
}

interface IFlowState {
  country?: ICountryAssetLite;
  phone?: string;
  countries?: ICountryAsset[];
  flow_type?: string;
  successScreen?: { title: string; description: string; withAddPost?: boolean };
  confirmationScreen?: {
    title: string;
    description: string;
    callBack: () => void;
  };
  errorScreen?: {
    title: string;
    description: string;
    cbTranslation?:string;
    callBack: () => void;
  };
  screenType?: SsoScreenTypes;
  verificationType?: IVerification;
  ssoConfigurations?: ISsoConfiguration;
  screensFlow?: SsoScreenTypes[];
  otpData?: ILinkOtpData;
  memberData? :IMember
}

const AppContext = createContext<IAppContext>({});

export function useSsoContext(): IAppContext {
  return useContext(AppContext);
}

const ssoReducer = (state: IFlowState, action: Partial<IFlowState>) => {
  if (action?.hasOwnProperty("screenType")) {
    const screenIndex = state.screensFlow.indexOf(action.screenType);
    if (screenIndex !== -1) {
      state.screensFlow = state.screensFlow.slice(0 , screenIndex + 1 );
    } else {
      state.screensFlow.push(action.screenType);
    }
  }
  return { ...state, ...action };
};

const { getItem: getLocalStorage } = localStorageUtility();
const AppContextProvider: FC<IAppContext> = ({
  children,
  countries,
  ssoConfigurations,
  otpData,
}) => {
  const screenType = Auth.getInstance().screenType;
  const phoneCode = getCountryByCountryAbbreviation(countries,Auth.getInstance().country);
  const memberData = Auth.getInstance().memberData;
  useGtmDefaultData();
  const initialState: IFlowState = {
    phone: getLocalStorage(LocalStorageConstants.PHONE_NUMBER) || "",
    flow_type: "",
    countries,
    country: filterCountryByCountryCode(countries,phoneCode.phone_code),
    screenType: screenType,
    successScreen: { title: "", description: "", withAddPost: true },
    confirmationScreen: {
      title: "",
      description: "",
      callBack: () => {},
    },
    errorScreen: {
      title: "",
      description: "",
      cbTranslation:"",
      callBack: () => {},
    },
    verificationType: "whatsApp",
    ssoConfigurations,
    screensFlow: [screenType],
    otpData,
    memberData: memberData
  };
  const [flowState, dispatch] = useReducer(ssoReducer, initialState);
  useEffect(() => {
    if (ssoConfigurations) {
      dispatch({ssoConfigurations})
    }
  }, [ssoConfigurations]);

  return (
    <AppContext.Provider value={{ flowState, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
