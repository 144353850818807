import {useEffect} from "react";
import {getQueryStringValue} from "../helpers";
import {AuthenticateQueryStringConstants, OPEN_SSO_MODAL_EVENT, ScreenConstants, SsoScreenTypes} from "../constants";
import Auth from "../auth";


const Login = () => {
    const getDialogType = (): SsoScreenTypes => {
        const allowedDialogTypes = [
            ScreenConstants.COMPLETE_REGISTER,
            ScreenConstants.LOGIN_SCREEN,
        ];

        const dialogTypeParam: any = getQueryStringValue(AuthenticateQueryStringConstants.DIALOGTYPE);
        const dialogType: string = allowedDialogTypes.includes(dialogTypeParam) ? dialogTypeParam! : ScreenConstants.LOGIN_SCREEN;
        return (dialogType) as SsoScreenTypes;
    };

    useEffect(() => {
      login().then().catch((e) => {
          console.error('error while open slr popup in login page->', e.message)
      })
    }, [])

    const login = async () => {
        const authInstance = Auth.getInstance();
        const {country , language ,platform ,cityId} = authInstance;
        authInstance.screenType = getDialogType();
        const redirectUrl = getQueryStringValue(AuthenticateQueryStringConstants.REDIRECT_URL);

        if (authInstance?.identity?.id && authInstance?.identity?.isNewUser === false) {
            //redirect if the user try to back to authenticate page (full page) after login and the user is loggedIn and verified
            window.location.href = redirectUrl;
            return;
        }
        document.dispatchEvent(
            new CustomEvent(OPEN_SSO_MODAL_EVENT, {
                detail: {
                    country,
                    platform,
                    language,
                    cityId,
                    redirectUrl:redirectUrl
                },
            })
        );
    }

    return (
        <></>
    )

}
export default Login;
