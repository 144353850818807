import { useEffect } from "react";
import { OPEN_SSO_MODAL_EVENT, ScreenConstants } from "../constants";
import Auth from "../auth";
import {OpenSlrPopEventDetail} from "../models/ssoConfigurations";

//for testing localhost:3000?slrSuccess=true&phone_number=0799740079&otp_code=123123
// localhost:3000?slrSuccess=true&email=abuhannoud.bashar%40gmail.com&otp_code=228496
//abuhannoud.bashar%40gmail.com/861071
export function useOtpLink() {
    useEffect(() => {
        const authInstance = Auth.getInstance();
        const { country, auth, platform, language , cityId } = authInstance;

        const handleOtpLinkVerification = (detail:OpenSlrPopEventDetail) => {
            authInstance.screenType = ScreenConstants.VERIFY_VIA_OTP_LINK;
            document.dispatchEvent(new CustomEvent(OPEN_SSO_MODAL_EVENT, { detail }));
            window.history.replaceState(null, null, '/');
        };

        const phoneNumberMatch = window.location.search.match(/slrSuccess=true&phone_number=(\d+)&otp_code=(\d+)/);
        if (phoneNumberMatch) {
            handleOtpLinkVerification({
                country,
                deviceUUID: auth.deviceUUID,
                platform,
                language,
                cityId,
                phoneNumber: phoneNumberMatch[1],
                otp: phoneNumberMatch[2],
                linkType: "otpLink",
            });
        }

        const emailMatch = window.location.search.match(/slrSuccess=true&email=(\S+)&otp_code=(\d+)/);
        if (emailMatch) {
            handleOtpLinkVerification({
                country,
                deviceUUID: auth.deviceUUID,
                platform,
                language,
                cityId,
                email: decodeURIComponent(emailMatch[1]),
                otp: emailMatch[2],
                linkType: "verifyEmail",
            });
        }
    }, []);
}
