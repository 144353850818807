import {useTheme} from "styled-components";
import {parseUTMs} from "./parseUTMs";
import {ICategoryLiteAsset} from "../models/Assets/ICategoryAsset";
import {ICpOption} from "../models/Assets/IDynamicAttributes";
import {INeighborhoodLiteAsset} from "../models/Assets/INeighborhoodAsset";
import {ICityLiteAsset} from "../models/Assets/ICityAsset";
import {capitalized, isClientSide, labelHelper} from "../helpers";
import envConfig from "../env.config";

export const useGtmDefaultData = () => {
    const {locale, isLoggedIn, country, device, memberData, city} = useTheme();
    if (city) setGtmExtraData({city});
    if (!isClientSide()) return;
    // eslint-disable-next-line
    const {utm_medium, utm_campaign, utm_source} = parseUTMs();
    // @ts-ignore
    window.gtmDefaultParams = {
        LoggedIn: isLoggedIn || "empty",
        Language: locale || "empty",
        Country: country || "empty",
        Device: device || "empty",
        MemberID: memberData?.id || "empty",
        Version: envConfig.APP_VERSION || "empty",
        //@todo revert this after experiment with Adey for Google Analytics UTM checking
        // cMedium: utm_medium,
        // cName: utm_campaign,
        // cSource: utm_source,
    };
};

interface IData {
    category?: ICategoryLiteAsset;
    subCategory?: ICategoryLiteAsset;
    city?: ICityLiteAsset;
    neighborhoods?: INeighborhoodLiteAsset[];
    selectedCpsOptions?: ICpOption[];
}

export const cleanGtmExtraData = () => {
    setGtmExtraData(null);
};

export const setGtmExtraData = (data: IData = null) => {
    const {selectedCpsOptions, category, subCategory, city, neighborhoods} =
    data || {};
    const extraData = {
        CP1: labelHelper(selectedCpsOptions?.[0], "en") || "empty",
        CP2: labelHelper(selectedCpsOptions?.[1], "en") || "empty",
        CP3: labelHelper(selectedCpsOptions?.[2], "en") || "empty",
        CP4: labelHelper(selectedCpsOptions?.[3], "en") || "empty",
        Category: category?.reporting_name || "empty",
        City: capitalized(city.name_english)/*capitalized(urlHelper(city, "en")).replaceAll("-", " ") || "empty"*/,
        Neighborhood: labelHelper(neighborhoods?.[0], "en") || "empty",
        SubCategory: subCategory?.reporting_name || "empty",
    };
    // @ts-ignore
    window.gtmExtraData = extraData;
    return extraData;
};
