import React, {
  Fragment,
  Suspense,
  useEffect,
  useReducer,
  useState,
} from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { CLOSE_SSO_MODAL_EVENT, OPEN_SSO_MODAL_EVENT } from "./constants";
import defaultTheme from "./global/theme";
import AppContextProvider from "./contexts/AppContextProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/login";
import Test from "./pages/test";
import { getQueryStringValue } from "./helpers";
import useGetEssentialDataAndTranslations from "./hooks/useGetEssentialDataAndTranslations";
import { useOtpLink } from "./hooks/useOtpLink";
import { ILinkOtpData, ILinkType } from "./models/otp";
import { OpenSlrPopEventDetail } from "./models/ssoConfigurations";

const SsoFlow = React.lazy(() => import("./ComponentScreeens"));

interface ThemeAction {
  type: "SET_THEME";
  data: DefaultTheme;
}

const App = () => {
  const [isSsoModalOpen, setIsSsoModalOpen] = useState(false);
  const [otpData, setOtpData] = useState<ILinkOtpData>();
  const themeReducer = (state: DefaultTheme, action: ThemeAction) => {
    switch (action.type) {
      case "SET_THEME":
        return {
          ...state,
          ...action.data,
        };
      default:
        return state;
    }
  };

  const [theme, dispatch] = useReducer(themeReducer, defaultTheme);
  theme.direction = theme.locale === "ar" ? "rtl" : "ltr";
  theme.isLTR = theme.locale === "en";
  theme.isRTL = theme.locale === "ar";
  theme.locale = theme.locale === "en" ? "en" : "ar";
  theme.isMobile = theme.device === "mobile";
  theme.isDesktop = theme.device === "desktop";
  const { countries, cities, ssoConf, translations } =
    useGetEssentialDataAndTranslations(theme);
  theme.city = cities?.find((city) => city.id === Number(theme.cityId));
  theme.translations = translations;

  useEffect(() => {
    document.addEventListener(CLOSE_SSO_MODAL_EVENT, () => {
      setIsSsoModalOpen(false);
    });
  }, []);

  useEffect(() => {
    document.addEventListener(
      OPEN_SSO_MODAL_EVENT,
      async (event: CustomEvent<OpenSlrPopEventDetail>) => {
        dispatch({
          type: "SET_THEME",
          data: {
            locale: event.detail.language,
            country: event.detail.country,
            device: event.detail.platform,
            cityId: event.detail.cityId,
            redirectUrl: event.detail.redirectUrl,
          },
        });
        setOtpData({
          otp: event.detail.otp || "",
          phoneNumber: event.detail.phoneNumber || "",
          email: event.detail.email || "",
          linkType: event.detail.linkType as ILinkType,
        });
        setIsSsoModalOpen(true);
      }
    );
  }, []);

  //this  hook used to handle otp link
  useOtpLink();
  useEffect(() => {
    if (window.location.href.includes("/login?redirectUrl=")) {
      const country = getQueryStringValue("country");
      const platform = getQueryStringValue("platform");
      const language: any = getQueryStringValue("language");
      const cityId = getQueryStringValue("cityId");

      dispatch({
        type: "SET_THEME",
        data: {
          locale: language,
          country: country,
          device: platform,
          fullPage: true,
          cityId: Number(cityId),
        },
      });
    }
  }, []);
  if (countries.length === 0 || !translations) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <ThemeProvider
        theme={{
          ...theme,
        }}
      >
        {theme.fullPage && (
          <head>
            <meta
              name="viewport"
              key="viewport"
              content="width=device-width, height=device-height ,initial-scale=1.0, shrink-to-fit=no"
            />
            <meta name="robots" content="noindex, nofollow" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <title>{translations.openSooqSso.title}</title>
          </head>
        )}
        {isSsoModalOpen && (
          <AppContextProvider
            countries={countries}
            ssoConfigurations={ssoConf}
            otpData={otpData}
          >
            <Suspense fallback={<div></div>}>
              <SsoFlow setIsSsoModalOpen={setIsSsoModalOpen} />
            </Suspense>
          </AppContextProvider>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/test" element={<Test />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
