interface EnvConfigTypes {
    OPENSOOQ_SSO_BASE_DOMAIN: string
    API_URL_CLIENT: string
    AJAX_API: string
    PUBLIC_FOLDER_CDN: string
    FONT_PATH: string
    COOKIES_PREFIX: string
    TOKEN_EXPIRED_IN_MINUTES: string
    DEFAULT_NUMBER: string
    APP_VERSION: string
    DEFAULT_PASSWORD: string
    ADD_POST_TEMPLATE_URL: string
    OPENSOOQ_BASE_DOMAIN:string
    NODE_API_URL_CLIENT: string
    LOGGING_URL_CLIENT?: string
    LOGGING_SERVER_KEY?:string
}

const envConfig: EnvConfigTypes = {
    OPENSOOQ_SSO_BASE_DOMAIN: process.env.REACT_APP_OPENSOOQ_SSO_BASE_DOMAIN ?? process.env.NEXT_PUBLIC_OPENSOOQ_SSO_BASE_DOMAIN ?? '',
    OPENSOOQ_BASE_DOMAIN: process.env.REACT_APP_OPENSOOQ_BASE_DOMAIN_SSO ?? process.env.NEXT_PUBLIC_OPENSOOQ_BASE_DOMAIN_SSO ?? '',
    API_URL_CLIENT: process.env.REACT_APP_API_URL_CLIENT_SSO ?? process.env.NEXT_PUBLIC_API_URL_CLIENT_SSO ?? '',
    NODE_API_URL_CLIENT: process.env.REACT_APP_NODE_API_URL_CLIENT_SSO ?? process.env.NEXT_PUBLIC_NODE_API_URL_CLIENT_SSO ?? '',
    AJAX_API: process.env.REACT_APP_AJAX_API_SSO ?? process.env.NEXT_PUBLIC_AJAX_API_SSO ?? '',
    PUBLIC_FOLDER_CDN: process.env.REACT_APP_PUBLIC_FOLDER_CDN_SSO ?? process.env.NEXT_PUBLIC_PUBLIC_FOLDER_CDN_SSO ?? '',
    FONT_PATH: process.env.REACT_APP_FONT_PATH_SSO ?? process.env.NEXT_PUBLIC_FONT_PATH_SSO ?? '',
    COOKIES_PREFIX: process.env.REACT_APP_COOKIES_PREFIX_SSO ?? process.env.NEXT_PUBLIC_COOKIES_PREFIX_SSO ?? '',
    TOKEN_EXPIRED_IN_MINUTES: process.env.REACT_APP_TOKEN_EXPIRED_IN_MINUTES_SSO ?? process.env.NEXT_PUBLIC_TOKEN_EXPIRED_IN_MINUTES_SSO ?? "",
    DEFAULT_NUMBER: process.env.REACT_APP_DEFAULT_NUMBER_SSO ?? process.env.NEXT_PUBLIC_DEFAULT_NUMBER_SSO ?? "",
    DEFAULT_PASSWORD: process.env.REACT_APP_DEFAULT_PASSWORD_SSO ?? process.env.NEXT_PUBLIC_DEFAULT_PASSWORD_SSO ?? "",
    APP_VERSION: process.env.REACT_APP_APP_VERSION_SSO ?? process.env.NEXT_PUBLIC_APP_VERSION_SSO ?? "",
    ADD_POST_TEMPLATE_URL: process.env.REACT_APP_ADD_POST_TEMPLATE_URL_SSO ?? process.env.NEXT_PUBLIC_ADD_POST_TEMPLATE_URL_SSO ?? "",
    LOGGING_URL_CLIENT: process.env.REACT_APP_LOGGING_URL_CLIENT ?? process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT ?? process.env.NEXT_PUBLIC_LOGGING_URL_CLIENT ?? "",
    LOGGING_SERVER_KEY: process.env.REACT_APP_LOGGING_SERVER_KEY ?? process.env.REACT_APP_NEXT_PUBLIC_LOGGING_SERVER_KEY ?? process.env.NEXT_PUBLIC_LOGGING_SERVER_KEY ?? "",
};
export default envConfig
